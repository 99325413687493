<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>
    <b-overlay
      :show="show"
      rounded="lg"
      no-wrap
      style="z-index:99999;"
    >
      <template #overlay>
        <div class="d-flex align-items-center flex-column">
          <b-img
            src="@/assets/images/LOGO FILES//PNG/3.png"
            class="pb-50"
            width="auto"
            height="120px"
            alt="Logo"
          />
          <b-spinner
            class="d-inline-block"
            label="Spinning"
          />
        </div>
      </template>
    </b-overlay>
    <div class="whats-app-contact">
      <a
        href="http://Wa.me/966564460371"
        class="float"
        target="_blank"
        :title="$t('contactWithUs')"
      >
        <i class="my-float">
          <img
            src="@/assets/images/icons/whatsapp.png"
            class="float-icon"
            alt=""
            srcset=""
          >
        </i>
      </a>
    </div>
    <!-- <scroll-to-top v-if="enableScrollToTop" /> -->
  </div>
</template>
<script src="@/pages/Shared/html2canvas.js"></script>
<script src="@/pages/Shared/html2canvas.js"></script>
<script>
// This will be populated in `beforeCreate` hook
import navMenuItems from "@/navigation/vertical";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";

import { useWindowSize, useCssVar } from "@vueuse/core";

import store from "@/store";
import { BIconNutFill } from "bootstrap-vue";
import ScrollToTop from "@core/components/scroll-to-top/ScrollToTop.vue";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    ScrollToTop,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  data() {
    return {
      navMenuItems,
      pathName: this.$route.name,
      hideActions: false,
      isDiscribed: false,
      selectedItem: {
        screenShot: "",
        title: "",
        phone: "",
        email: "",
        message: "",
      },
      routesBasedOnConditions: [
        'deservedFees',
        'deservedFeesCollection'
      ]
    };
  },
  computed: {
    ...mapGetters({
      show: "app/overlay",
    }),
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
    isLoginScreen() {
      if (this.$route.name === "login") return true;
      return false;
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();
    const { enableScrollToTop } = $themeConfig.layout;

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });
    return {
      skinClasses,
      enableScrollToTop,
    };
  },
  mounted() {
    this.$i18n.locale = this.currentLang;
    this.$store.dispatch("appConfig/changeLang", this.$i18n.locale || "en");
    if (this.profile.userId || this.profile.isAdmin) this.Startup();
    window.localStorage.USERID = this.profile.userId;
    window.localStorage.USERNAME = this.profile.userName;
    this.selectedItem.phone = this.profile.phone;
    this.selectedItem.email = this.profile.email;
  },
  watch: {
    profile(newVal) {
      if (newVal.isAdmin || this.profile.userId) this.Startup();
      window.localStorage.USERID = this.profile.userId;
      window.localStorage.USERNAME = this.profile.userName;
      this.selectedItem.phone = this.profile.phone;
      this.selectedItem.email = this.profile.email;
    },
  },
  methods: {
    hide() {
      this.hideActions = true;
    },
    makeAction() {
      this.hide();
      this.$refs["feedback-modal"].hide();
      setTimeout(() => {
        this.takeScreenShot();
        this.hideActions = false;
        this.openModal();
      }, 500);
    },
    ...mapActions({
      setBranches: "app/setBranches",
      setYears: 'app/setYears',
    }),
    ...mapMutations({
      setCurrentYear: 'app/setCurrentYear',
      setCompany: "app/setCompany"
    }),
  async  Startup() {
     await this.get({ url: "aggregates/StartUp" })
        .then((data) => {
          this.setBranches(data.branches);
          data.currentCompany ? (this.setCompany(data.currentCompany) , this.mainCompany = data.currentCompany ) : null
          // this.setCompany(data.currentCompany);
          // this.mainCompany = data.currentCompany;
        }).then(() => {
          this.get({ url: 'FiscalYears' })
        .then((data) => {
          if (this.profile.isAdmin || this.profile.permissions.indexOf('viewAllFiscalYears') > -1) {
            const dtaLength = data.length
            data.push({
              year: null,
              startDate: data[0].startDate,
              endDate: data[dtaLength - 1].endDate,
            })
            this.setYears(data);
          }
          if (this.profile.isAdmin === false && this.profile.permissions.indexOf('viewFiscalYears') === -1) {
          this.setYears(data);
          }
          if (this.profile.isAdmin === false && this.profile.permissions.indexOf('viewFiscalYears') > -1) {
            this.setYears(data);
          }
          if (JSON.stringify(this.currentYear) === '{}') {
            const year = { ...(data.find((item) => item.year === new Date().getFullYear())) }
            this.setCurrentYear(year)
          }
        }).catch(() => {
          if (this.profile.isAdmin === false && this.profile.permissions.indexOf('viewFiscalYears') === -1) {
            this.setYears(data);
          }
        })
        })
        .then(() => {
          this.get({ url: "FavoriteRoutes" }).then((data) => {
            if (data.length > 0) {
              data.forEach((res) => {
                this.navMenuItems.forEach((parent) => {
                  if (parent.children) {
                    parent.children.forEach((children) => {
                      if (res.routeUrl === children.route) {
                        res.title = children.title;
                      }
                    });
                  }
                });
                this.favMenu = data;
                if(!this.currentBranch.isTaxCollectionActivated) {
                    this.routesBasedOnConditions.forEach(element => {
                    this.favMenu = this.favMenu.filter((x) => x.routeUrl !== element)
                  });
                }
                localStorage.favMenu = JSON.stringify(this.favMenu);
              });
            } else {
              this.favMenu = [];
              localStorage.favMenu = JSON.stringify(this.favMenu);
            }
          });
        });
    },
  },
};
</script>
<style>
* {
  word-wrap: normal;
  letter-spacing: normal;
}

#screenImage canvas {
  width: 200px !important;
  height: 100px !important;
}

.html2canvas-container {
  width: 100% !important;
  height: 3000px !important;
}

#example-datepicker__dialog_ {
  left: auto !important;
}

.dateLabel {
  margin-bottom: 7px;
}

.payment-section {
  border: 1px solid #7063ea;
  border-radius: 15px;
}

.tree-text {
  font-size: 15px;
}

.toggle-icon {
  font-size: 16px;
  text-align: center;
}

.tree-icon {
  margin-right: 5px !important;
}

.text-nowrap {
  text-overflow: ellipsis;
  overflow: hidden;
}

button:disabled {
  cursor: not-allowed;
}

@media (max-width: 767px) {
  .table-responsive .select-size-sm {
    position: static !important;
  }
}

@media (min-width: 768px) {
  .table-responsive {
    overflow: visible;
  }
}

@media (max-width: 768px) {
  #app .whats-app-contact a {
    right: 12px;
    bottom: 35px;
  }

  #app .whats-app-contact i img {
    margin-bottom: 15px;
    margin-right: -17px;
  }
}
.custom-switch .custom-control-label {
  font-size: 15px;
}
.introjs-tooltip-title {
  color: #1e52bf;
}

.introjs-skipbutton {
  color: #ea5455;
}
.b-calendar .b-calendar-inner {
  min-width: 100%;
}
.b-form-btn-label-control.form-control > .dropdown-menu {
  width: max-content;
}
.main-menu .navbar-header .navbar-brand {
  margin-top: 1.3rem;
}
.navigation li a svg {
  width: 16px !important;
  height: 16px !important;
}
.main-menu.menu-light .navigation > li ul .active,
.btn-primary,
.customizer .customizer-toggle,
.page-item.active .page-link,
.horizontal-menu
  .header-navbar.navbar-horizontal
  ul#main-menu-navigation
  > li.active
  > a,
.main-menu.menu-dark .navigation > li.active > a,
.bg-primary {
  /* background: linear-gradient(45deg, #fd1d1d, #e1306c, #c13584, #833ab4, #5851db, #405de6)!important; */
  background: linear-gradient(45deg, #fc8153, #cd35dd, #6031ef) !important;
  /* background: linear-gradient(45deg, #405de6,#5851db, #833ab4, #c13584, #e1306c, #fd1d1d)!important; */
}
.main-menu.menu-dark .navigation > li ul .active,
.badge-primary,
.main-menu.menu-light .navigation > li.active > a,
.dark-layout .nav-pills .nav-item .nav-link.active,
.nav-pills .nav-link.active {
  /* background: linear-gradient(45deg, #fd1d1d, #e1306c, #c13584, #833ab4, #5851db, #405de6)!important; */
  background: linear-gradient(45deg, #fc8153, #cd35dd, #6031ef) !important;
}
.float {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 20px;
  right: 30px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 3px 18px #999;
  z-index: 10000;
}
.float-icon {
  position: fixed;
  width: 30px;
  height: 30px;
  bottom: 29px;
  right: 39px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
.my-float {
  margin-top: 16px;
}
.btn-whatsApp {
  border-color: #25d366 !important;
  background-color: #25d366 !important;
}
.main-font-size {
  font-size: 14px;
}
.main-space {
  letter-spacing: 1.1px;
}
.center-element {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
